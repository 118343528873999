import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axios from 'axios';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses
} from '@mui/material';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { isEmpty } from 'lodash';

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden'
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            Progress
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const [loading, setLoading] = useState(true)
    const theme = useTheme();
    const URL = process.env.REACT_APP_API_URL + 'location-filter/';
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };
    axios.get(URL, config).then((rest) => {
        if(!isEmpty(rest.data[0])){
            localStorage.setItem('data_client', JSON.stringify(rest.data[0]))
            setLoading(false)
        }

    })

    return (
        
            <CardStyle>
            <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                       {!loading &&(
                       <ListItemText
                            primary={
                                <Typography variant="subtitle1" >
                                    Mes INFOS
                                </Typography>
                            }
                            secondary={<Typography variant="caption"> 
                            Nom : {JSON.parse(localStorage.getItem('data_client'))?.label || 'N/A'} <br />
                            Adresse : {JSON.parse(localStorage.getItem('data_client'))?.address[0]?.address || 'N/A'} <br />
                            Postal Code : {JSON.parse(localStorage.getItem('data_client'))?.address[0]?.postalCode || 'N/A'} <br />
                            Province : {JSON.parse(localStorage.getItem('data_client'))?.address[0]?.province || 'N/A'} <br />
                            City : {JSON.parse(localStorage.getItem('data_client'))?.address[0]?.city || 'N/A'} <br />
                            Téléphone : {JSON.parse(localStorage.getItem('data_client'))?.address[0]?.phone || 'N/A'}
                            </Typography>}
                            
                        />)}
                    </ListItem>
                </List>
                
            </CardContent>
        </CardStyle> 
    );
};

export default MenuCard;

// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill,IconTags, IconLocation } from '@tabler/icons';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WidgetsIcon from '@mui/icons-material/Widgets';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTags,
    ListAltIcon,
    IconLocation,
    OpenInNewIcon,
    WidgetsIcon,ShoppingCartIcon,InventoryIcon

};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Products',
            title: 'Les Services',
            type: 'item',
            url: '/',
            icon: icons.WidgetsIcon,
            breadcrumbs: false
        },
        {
            id: 'cart',
            title: 'Panier',
            type: 'item',
            url: '/cart',
            icon: icons.ShoppingCartIcon,
            breadcrumbs: false
        },
        {
            id: 'order',
            title: 'Archives',
            type: 'item',
            url: '/orders',
            icon: icons.InventoryIcon,
            breadcrumbs: false
        },
        {
            id:'customLink',
            title:'Description Des Services Offert.',
            type:'item',
            url:'https://groupedomotec.com/description-des-services-offerts/',
            icon:icons.OpenInNewIcon,
            breadcrumbs: false,
            customLink:true
        }
    ]
};

export default utilities;
